<template>
  <el-dialog v-model="dialog_visible" modal-class="monitor-dialog-modal" custom-class="monitor-dialog"
             :close-on-click-modal="false" :close-on-press-escape="false" align-center :lock-scroll="false"
             :show-close="false" :destroy-on-close="true" @close="beforeClose"
             :style="'background-image: url(' + monitor_dialog_bg + ');background-size: cover'">
    <template #header>
      <div class="monitor-dialog-header">
        <div class="monitor-dialog-title">视频监控</div>
        <div class="monitor-dialog-close" @click="close"></div>
      </div>
    </template>
    <div class="monitor-dialog-content">
      <el-scrollbar class="slider">
        <div class="camera-list">
          <!-- :class="[{selected: i === 2}]" -->
          <div v-for="iter in equipmentList" :key="iter.id"
               :class="{'selected':iter.id === currPlayId}"
               class="camera-item"
               @click="switchCamera(iter)">
            <span>{{ iter.name }}</span>
          </div>
        </div>
      </el-scrollbar>
      <div class="main">
        <div v-for="iter in playerList" :key="iter.id"
             v-show="currPlayId === iter.id"
             style="height: 100%;">
          <div :id="iter.elementId" class="video-player"></div>
        </div>
      </div>
    </div>
    <!-- 截图查看 -->
    <screenshot-show v-if="screenshotImage"
                     :src="screenshotImage"
                     :save-image-obj="saveImageObj"
                     @close="screenshotImage = ''"/>
  </el-dialog>
</template>

<script>
import monitorStore, {FlvPlayer, VideotapeTask} from "@/utils/monitor";
import ScreenshotShow from "@/view/visualization/components/ScreenshotShow";
import {blobToFile, fileToDataURL} from "@/utils/common";
import dayjs from "dayjs";

export default {
  name: 'monitorDialog',
  components: {ScreenshotShow},
  data() {
    return {
      monitor_dialog_bg: require('@/static/img/monitor/monitor-dialog-bg.png'),
      dialog_visible: false,
      equipmentList: [],
      //
      playerList: [],
      // 当前正在播放
      currPlayId: '',
      //
      screenshotImage: '',
      // 当前正在录像
      currVideoCoverEvent: new VideotapeTask()
    }
  },
  methods: {
    /**
     * 播放指定摄像头
     * @param id
     */
    monitorPlay(id){
      this.currPlayId = id
      //
      const item = this.playerList.find(iter => iter.id === id)
      if (item) {
        // 播放当前摄像头
        this.$nextTick(() => {
          item.play()
        })
      } else {
        // 创建
        const iter = new FlvPlayer(id, `monitor-${id}`)
        // 截屏事件处理
        iter.screenshot((blob, obj) => {
          this.saveImageObj = obj
          //
          fileToDataURL(blob, (url) => {
            this.screenshotImage = url
          })
        })
        // 追加
        this.playerList.push(iter)
        // 录像
        iter.appendContextmenu({
          text: '开始录像',
          click: () => {
            Reflect.has(iter, 'showRecording') && iter.showRecording()
            iter.manualScreenshot(img => {
              fileToDataURL(img, url => {
                this.currVideoCoverEvent.setTask({
                  id: iter.id,
                  value: {
                    imgBase64: url,
                    imgFile: blobToFile(img),
                    startTime: dayjs(new Date).format('YYYY-MM-DD HH:mm:ss'),
                    endTime: '',
                    equipmentId: iter.id
                  }
                })
              })
            })
          }
        })
        iter.appendContextmenu({
          text: '结束录像',
          click: () => {
            Reflect.has(iter, 'showRecording') && iter.hideRecording()
            // 确保正在录像中
            if (this.currVideoCoverEvent.hasTask()) {
              const item = this.currVideoCoverEvent.getTask(iter.id)
              if (item) {
                //
                monitorStore.setShowImgBase64(item.value.imgBase64)
                // 更新结束时间
                item.value.endTime = dayjs(new Date).format('YYYY-MM-DD HH:mm:ss')
                //
                monitorStore.setCurrVideoCoverEvent(item.value)
              }
            }
          }
        })
        // 播放
        this.$nextTick(() => {
          iter.play()
        })
      }

      // 暂停其他摄像头
      this.playerList.forEach(iter => iter.id !== id && iter.suspend())
    },
    /**
     * 切换摄像头
     */
    switchCamera(iter){
      this.monitorPlay(iter.id)
    },
    /**
     * 销毁播放
     * @param id
     */
    monitorDestruction(id){
      // 销毁单个
      if (id) {
        const index = this.playerList.findIndex(iter => iter.id === id)
        if (index !== -1) {
          this.playerList[index].destruction()
          this.playerList.splice(index, 1)
        }
        return
      }
      // 销毁所有
      this.playerList.forEach(iter => iter.destruction())
      this.playerList = []
    },
    /**
     *
     */
    beforeClose(){
      this.monitorDestruction(null)
    },
    /**
     * @param arr
     */
    open(arr){
      // loading
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      this.equipmentList = arr

      // 显示弹层
      this.dialog_visible = true
      // 播放第一项
      this.$nextTick(() => {
        this.monitorPlay(this.equipmentList[0].id)
      })
      //
      loading.close()
    },
    /**
     *
     */
    close() {
      this.dialog_visible = false
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss">
.monitor-dialog {
  --el-dialog-width: 1420px;
  height: 800px;
  --el-dialog-bg-color: transparent;
  --el-box-shadow: none;

  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.video-player{
  width: 100%;
  height: 100%;
  .video-player{
    width: 100%;
    height: 100%;
  }
}
.monitor-dialog-header {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-content {
  width: 1400px;
  height: 736px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  display: flex;

  .slider {
    width: 240px;
    height: 716px;

    .camera-list {
      .camera-item {
        width: 240px;
        height: 56px;
        padding: 0 16px;
        font-size: 16px;
        font-weight: bold;
        line-height: 56px;
        background: rgba(0, 157, 255, 0.1);
        color: #80CEFF;
        cursor: pointer;

        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.selected {
          background: rgba(0, 157, 255, 0.3);
          color: #009DFF;
        }

        & + .camera-item {
          margin-top: 16px;
        }
      }
    }
  }

  .main {
    width: 1100px;
    height: 716px;
    margin-left: 20px;
  }
}
</style>