<template>
  <el-dialog v-model="dialog_visible" modal-class="monitor-dialog-modal" custom-class="monitor-dialog"
             :close-on-click-modal="false" :close-on-press-escape="false" align-center
             :show-close="false"
             :style="'background-image: url(' + monitor_dialog_bg + ');background-size: cover'">
    <template #header>
      <div class="monitor-dialog-header">
        <div class="monitor-dialog-title">截图</div>
        <div class="monitor-dialog-close" @click="close"></div>
      </div>
    </template>
    <div class="monitor-dialog-content screenshot-show flex-direction align-end"
         v-loading="playerListLoading">
      <div class="image-container">
        <img :src="src" alt="" style="width: 100%;height: 100%;object-fit: contain;background-color: aliceblue;">
      </div>
      <div class="action-group marginTop20px">
        <el-button class="action-button" @click="close">取消</el-button>
        <el-button class="action-button" @click="download"><el-icon style="margin-right: 8px;font-size:24px;color: #009DFF;"><Download /></el-icon>保存到本地</el-button>
        <el-button class="action-button primary" type="primary" @click="saveImage">保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {systemFileScreenshot} from "@/api/monitor";
import {uploadFile} from "@/api/api";

export default {
  name: 'ScreenshotShow',
  props: {
    src: {
      type: String,
      default(){
        return ''
      }
    },
    saveImageObj: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data() {
    return {
      monitor_dialog_bg: require('@/static/img/monitor/monitor-dialog-bg.png'),
      dialog_visible: true,
      playerListLoading: false,
    }
  },
  methods: {
    saveImage(){
      this.playerListLoading = true

      const formData = new FormData()
      formData.append('file', this.saveImageObj.currScreenshotFile)
      uploadFile(formData).then(res => {
        systemFileScreenshot({
          equipmentId: this.saveImageObj.id,
          filePath: res.data
        }).then(() => {
          this.$message.success('保存成功')
          setTimeout(() => {
            this.playerListLoading = false
            this.close()
          }, 500)
        })
      })

    },
    /**
     * 下载
     */
    download(){
      if (this.saveImageObj.saveScreenshotBlob) {
        this.saveImageObj.saveScreenshotBlob()
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.monitor-dialog {
  --el-dialog-width: 1420px;
  height: 800px;
  --el-dialog-bg-color: transparent;
  --el-box-shadow: none;

  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.event-item {
  /deep/ .el-progress-bar__outer{
    background: #191922;
  }
  /deep/ .el-progress-bar__inner{
    background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
  }
}

.monitor-dialog-header {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-content {
  width: 1400px;
  height: 736px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
}

.screenshot-show{
  .image-container{
    width: 1360px;
    height: 656px;
    background-color: rgba(72, 187, 192, 0.2);
  }
  .action-group{
    .action-button{
      width: 160px;
      height: 40px;
      background: rgba(0,157,255,0.1);
      box-shadow: inset 0 0 4px 0 #009DFF;
      border-radius: 20px;
      border: 1px solid #009DFF;
      color: #ffffff;
    }
    .primary{
      background-color: #009DFF;
    }
  }
}

</style>